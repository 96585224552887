<template>
  <div class="promotion-screen">
    <div class="time-panel">{{ time }} {{ day }}</div>
    <div class="header">康一生®适生健康促进系统 V2.0</div>
    <div class="body">
      <div class="section">
        <div class="record-panel record-panel_promotion">
          <div class="title-promotion">
            <span class="blue font-20">促进记录</span>
            <span class="blue font-13">&nbsp;&nbsp;最近 30 天促进记录 - {{ promotionData.length }} 条</span>
          </div>
          <table class="table table-promotion">
            <colgroup>
              <col width="140px">
              <col width="100px">
              <col width="100px">
            </colgroup>
            <tr>
              <td>促进时间</td>
              <td>促进人</td>
              <td>促进类型</td>
              <td>促进记录</td>
            </tr>
          </table>
          <div class="table-content table-promotion-content">
            <table class="table table-promotion">
              <colgroup>
                <col width="140px">
                <col width="100px">
                <col width="100px">
              </colgroup>
              <tr v-for="(item, index) in promotionData" :key="index">
                <td>{{ item.cjsj }}</td>
                <td class="yellow">{{ item.cjr }}</td>
                <td>{{ item.cjlx }}</td>
                <td>{{ item.cjjl }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="record-panel record-panel_plan">
          <div class="title-plan">
            <span class="blue font-20">方案记录</span>
            <span class="blue font-13">&nbsp;&nbsp;方案日期：{{ planData.rq }}</span>
          </div>
          <table class="table table-plan">
            <colgroup>
              <col width="60px">
              <col width="60px">
            </colgroup>
            <tr>
              <td>时间</td>
              <td>类型</td>
              <td>名称</td>
              <td>使用方法</td>
              <td>备注</td>
            </tr>
          </table>
          <div class="table-content table-plan-content">
            <table class="table table-plan">
              <colgroup>
                <col width="60px">
                <col width="60px">
              </colgroup>
              <tr v-for="(item, index) in planData.list" :key="index">
                <td>{{ item.sjd }}</td>
                <td class="yellow">{{ item.lx }}</td>
                <td>{{ item.mc }}</td>
                <td>{{ item.syff }}</td>
                <td>{{ item.bz }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="record-panel record-panel_ask">
          <div class="title-ask">
            <span class="blue font-20">咨询记录</span>
          </div>
          <table class="table table-ask">
            <tr>
              <td>日期</td>
              <td>咨询专家</td>
              <td>生理数值对比情况</td>
              <td>方案措施</td>
            </tr>
          </table>
          <div class="table-content table-ask-content">
            <table class="table table-ask">
              <tr v-for="(item, index) in askData" :key="index">
                <td>{{ item.rq }}</td>
                <td class="yellow">{{ item.zxzj }}</td>
                <td>{{ item.slszdbqk }}</td>
                <td>{{ item.facs }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="profile-panel">
          <div class="title-profile">
            <span class="blue font-20">基础信息</span>
            <span class="profile-birthday" v-if="canShow">
              <img src="@/assets/img/promotion/birthday.png" alt="birthday" class="profile-img">
              <span class="profile-text">{{ profileData.birthday }}</span>
            </span>
          </div>
          <table class="table table-profile">
            <tr>
              <th>门店</th>
              <th>姓名</th>
              <th>性别</th>
              <th>年龄</th>
            </tr>
            <tr>
              <td>{{ profileData.ssmd }}</td>
              <td>{{ profileData.name }}</td>
              <td>{{ profileData.sex }}</td>
              <td>{{ profileData.age }}</td>
            </tr>
            <tr>
              <th>手机号</th>
              <th>家庭</th>
              <th>康龄</th>
              <th>服务员工</th>
            </tr>
            <tr>
              <td>{{ profileData.phone }}</td>
              <td>{{ profileData.jtgs }}</td>
              <td>{{ profileData.kl }}</td>
              <td>{{ profileData.staff }}</td>
            </tr>
            <tr>
              <th v-if="profileData.bysjh">备用手机号</th>
              <th>转介绍</th>
              <th>卡系</th>
              <th>会员状态</th>
            </tr>
            <tr>
              <td v-if="profileData.bysjh">{{ profileData.bysjh }}</td>
              <td>{{ profileData.zjs }}</td>
              <td>{{ profileData.kx }}</td>
              <td>{{ profileData.hyzt }}</td>
            </tr>
          </table>
          <div class="profile-row">
            <span class="name">遗传史</span>
            <span class="red" :title="profileData.ycs">{{ profileData.ycs }}</span>
          </div>
          <div class="profile-row">
            <span class="name">疾病</span>
            <span class="red" :title="profileData.jb">{{ profileData.jb }}</span>
          </div>
          <div class="profile-row">
            <span class="name">自觉主诉</span>
            <span class="red" :title="profileData.zjzs">{{ profileData.zjzs }}</span>
          </div>
          <div class="profile-row">
            <span class="name">备注</span>
            <span class="red" :title="profileData.bz">{{ profileData.bz }}</span>
          </div>
          <div class="profile-row-last">
            <span class="name">紧急联系人</span>
            <span class="red">{{ profileData.jjlxr }}</span>
            <span class="name">紧急联系人电话</span>
            <span class="red">{{ profileData.jjlxr_mobile }}</span>
          </div>
        </div>
        <div class="input-row">
          <div class="button-panel">
            <span class="button" @click="openModal('物质新增')">物质新增</span>
            <span class="button" @click="openModal('主诉新增')">主诉新增</span>
            <span class="button" @click="openModal('训练新增')">训练新增</span>
            <span class="button" @click="openModal('备注')">备注</span>
          </div>
        </div>
        <div class="input-panel">
          <div class="input-row">
            <span style="margin-right: 20px">时间：</span>
            <span>{{ time }}</span>
          </div>
          <textarea rows="6" class="textarea" placeholder="请输入" v-model="promotionText"></textarea>
        </div>
        <div class="input-panel">
          <div class="input-row">
            <span>问题上报</span>
            <div class="button-panel">
              <select class="select" v-model="selectedProblem">
                <option v-for="item in problemTypes" :key="item.id" :value="item.id">{{ item.label }}</option>
              </select>
              <span class="button" @click="submitProblem">确定</span>
            </div>
          </div>
          <textarea rows="6" class="textarea" placeholder="请输入" v-model="problemText"></textarea>
        </div>
        <div class="input-row">
          <span style="margin-right: 20px">下次促进时间：{{ nextTime }}</span>
          <div class="button-panel">
            <span class="button" @click="submitPromotion">确定录入</span>
            <span class="button" @click="goToNext">促进下一个</span>
            <span class="button" @click="$router.push('/base/promotion-manage')">促进结束</span>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="alarm-panel">
          <div class="title-alarm">
            <span class="blue font-20">预警记录</span>
            <span class="blue font-13">&nbsp;&nbsp;最近 30 天预警记录 - {{ alarmData.length }} 条</span>
          </div>
          <table class="table table-alarm">
            <colgroup>
              <col width="150px">
              <col>
              <col width="60px">
            </colgroup>
            <tr>
              <td>采集时间</td>
              <td>预警问题描述</td>
              <td>状态</td>
            </tr>
          </table>
          <div class="table-content table-alarm-content">
            <table class="table table-alarm">
              <colgroup>
                <col width="150px">
                <col>
                <col width="60px">
              </colgroup>
              <tr v-for="(item, index) in alarmData" :key="index">
                <td>{{ item.cjsj }}</td>
                <td>{{ item.yjwtms }}</td>
                <td>{{ item.zt }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="behavior-panel">
          <div class="title-behavior">
            <span class="blue font-20">行为记录</span>
          </div>
          <div class="table-behavior-content">
            <div class="table-section">
              <table class="table">
                <tr>
                  <td>最近 30 天摄入情况<br>「标准{{ behaviorData.bzsr }}」</td>
                </tr>
              </table>
              <div class="table-body">
                <table class="table">
                  <tr v-for="(item, index) in behaviorData.sjsrzl_list" :key="index">
                    <td>{{ item }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="table-section">
              <table class="table">
                <tr>
                  <td>最近 7 天有效消耗<br>「标准{{ behaviorData.bzxh }}」</td>
                </tr>
              </table>
              <div class="table-body">
                <table class="table">
                  <tr v-for="(item, index) in behaviorData.yxxh_list" :key="index">
                    <td>{{ item }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="table-section">
              <table class="table">
                <tr>
                  <td>最近 30 天训练</td>
                </tr>
              </table>
              <div class="table-body">
                <table class="table">
                  <tr v-for="(item, index) in behaviorData.xl_list" :key="index">
                    <td>{{ item }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="table-section">
              <table class="table">
                <tr>
                  <td>最近 30 天物质</td>
                </tr>
              </table>
              <div class="table-body">
                <table class="table">
                  <tr v-for="(item, index) in behaviorData.fj_list" :key="index">
                    <td>{{ item }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <table class="table table-behavior" style="font-size: 12px;">
            <tr>
              <td>最近7天睡眠数据 <span :class="behaviorCls(behaviorData.sm)">{{ behaviorData.sm }}</span> 条</td>
              <td>最近30天血压数据 <span :class="behaviorCls(behaviorData.xy)">{{ behaviorData.xy }}</span> 条</td>
              <td>最近30天血糖数据 <span :class="behaviorCls(behaviorData.xt)">{{ behaviorData.xt }}</span> 条</td>
            </tr>
            <tr>
              <td>最近60天人体成分 <span :class="behaviorCls(behaviorData.rtcf)">{{ behaviorData.rtcf }}</span> 条</td>
              <td>最近180天腰围数据 <span :class="behaviorCls(behaviorData.yw)">{{ behaviorData.yw }}</span> 条</td>
              <td>最近180天握力数据 <span :class="behaviorCls(behaviorData.wl)">{{ behaviorData.wl }}</span> 条</td>
            </tr>
            <tr>
              <td>最近180天血脂数据 <span :class="behaviorCls(behaviorData.xz)">{{ behaviorData.xz }}</span> 条</td>
              <td>最近180天尿酸数据 <span :class="behaviorCls(behaviorData.ns)">{{ behaviorData.ns }}</span> 条</td>
              <td>最近180天肺活量数据 <span :class="behaviorCls(behaviorData.fhl)">{{ behaviorData.fhl }}</span> 条</td>
            </tr>
            <tr>
              <td>最近180天平衡能力数据 <span :class="behaviorCls(behaviorData.phnl)">{{ behaviorData.phnl }}</span> 条</td>
              <td>最近365天身高数据 <span :class="behaviorCls(behaviorData.sg)">{{ behaviorData.sg }}</span> 条</td>
              <td>最近365天报告 <span :class="behaviorCls(behaviorData.bg)">{{ behaviorData.bg }}</span> 份</td>
            </tr>
          </table>
        </div>
        <div class="service-panel">
          <div class="title-service">
            <span class="blue font-20">服务记录</span>
            <span class="blue font-13">&nbsp;&nbsp;最近 30 天服务记录 - {{ serviceData.length }} 条</span>
          </div>
          <table class="table table-service">
            <colgroup>
              <col width="80px">
              <col width="80px">
            </colgroup>
            <tr>
              <td>服务时间</td>
              <td>服务人</td>
              <td>服务内容</td>
              <td>评价星级</td>
              <td>服务评价</td>
            </tr>
          </table>
          <div class="table-content table-service-content">
            <table class="table table-service">
              <colgroup>
                <col width="80px">
                <col width="80px">
              </colgroup>
              <tr v-for="(item, index) in serviceData" :key="index">
                <td>{{ item.fwsj.substr(0, 10) }}</td>
                <td>{{ item.fwr }}</td>
                <td>{{ item.fwnr }}</td>
                <td>{{ item.pjxj }}</td>
                <td>{{ item.fwpj }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Modal v-model="showModal" :title="modalTitle" class-name="vertical-center-modal" width="800" @on-ok="modalOK"
      @on-cancel="modalCancel">
      <Form :label-width="150">
        <template v-if="modalTitle === '物质新增'">
          <FormItem label="名称">
            <Select clearable v-model="fj.type_id" placeholder="请选择">
              <Option v-for="item in fjList" :value="item.type_id" :key="item.type_id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="使用方法">
            <Input v-model="fj.use_way" type="textarea" placeholder="请输入" />
          </FormItem>
          <FormItem label="使用开始时间">
            <DatePicker type="date" v-model="fj.start_time" placeholder="选择日期"></DatePicker>
          </FormItem>
        </template>
        <template v-else-if="modalTitle === '主诉新增'">
          <FormItem label="发现时间">
            <DatePicker type="date" v-model="zs.find_time" placeholder="选择主诉时间"></DatePicker>
          </FormItem>
          <FormItem label="身体最近有哪些不适">
            <Input v-model="zs.describe" type="textarea" placeholder="请输入" />
          </FormItem>
        </template>
        <template v-else-if="modalTitle === '训练新增'">
          <FormItem label="名称">
            <Select clearable v-model="xl.type_id" placeholder="请选择">
              <Option v-for="item in xlList" :value="item.type_id" :key="item.type_id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="训练方法">
            <Input v-model="xl.use_way" type="textarea" placeholder="请输入" />
          </FormItem>
          <FormItem label="训练开始时间">
            <DatePicker type="date" v-model="xl.start_time" placeholder="选择日期"></DatePicker>
          </FormItem>
        </template>
        <template v-else-if="modalTitle === '备注'">
          <FormItem label="备注">
            <Input v-model="newRemark" placeholder="请输入" />
          </FormItem>
        </template>
      </Form>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import boostService from '@/services/boostService';

export default {
	data() {
		return {
			uid: '',
			day: '',
			time: '',
			timer: null,
			profileData: {},
			promotionData: [],
			planData: {
				list: [],
				rq: '',
			},
			askData: [],
			alarmData: [],
			behaviorData: {
				xl_list: [],
				yxxh_list: [],
				sjsrzl_list: [],
				fj_list: [],
			},
			serviceData: [],
			promotionText: '',
			problemText: '',
			selectedProblem: '',
			problemTypes: [],
			nextTime: '',
			allTasks: [],
			showModal: false,
			modalTitle: '物质新增',
			fj: {},
			zs: {},
			xl: {},
			newRemark: '',
			fjList: [],
			xlList: [],
		};
	},
	computed: {
		canShow() {
			const b = dayjs(this.profileData.birthday);
			const today = dayjs();
			const curB = b.set('year', today.get('year'));
			const diff = curB.diff(today, 'd');
			return diff <= 30 && diff >= 0;
		},
	},
	methods: {
		setTimer() {
			this.timer = setTimeout(() => {
				const now = dayjs();
				this.day = `星期${['日', '一', '二', '三', '四', '五', '六'][now.day()]}`;
				this.time = now.format('YYYY-MM-DD HH:mm:ss');
				this.setTimer();
			}, 1000);
		},
		getProfile() {
			boostService.screenProfile({ member_id: this.uid }).then((data) => {
				this.profileData = data;
			});
		},
		getPromotion() {
			boostService.screenPromotion({ member_id: this.uid }).then((data) => {
				this.promotionData = data.list;
			});
		},
		getPlan() {
			boostService.screenPlan({ member_id: this.uid }).then((data) => {
				this.planData = data;
			});
		},
		getAsk() {
			boostService.screenAsk({ member_id: this.uid }).then((data) => {
				this.askData = data.list;
			});
		},
		getAlarm() {
			boostService.screenAlarm({ member_id: this.uid }).then((data) => {
				this.alarmData = data.list;
			});
		},
		getBehavior() {
			boostService.screenBehavior({ member_id: this.uid }).then((data) => {
				this.behaviorData = data;
			});
		},
		behaviorCls(val) {
			if (val === 0) return 'red';
			else if (val < 11) return 'blue';
			else return 'yellow';
		},
		getService() {
			boostService.screenService({ member_id: this.uid }).then((data) => {
				this.serviceData = data.list;
			});
		},
		getNextTime() {
			boostService.promotionTime().then((data) => {
				this.nextTime = data.yyrq;
			});
		},
		submitProblem() {
			if (!this.problemText.trim()) {
				this.$Message.error('请输入遇到的问题');
				return;
			}

			boostService
				.problemSubmit({
					member_id: this.uid,
					wtfl_id: this.selectedProblem,
					detail_info: this.problemText.trim(),
				})
				.then(() => {
					this.$Message.success('问题上报成功');
				});
		},
		submitPromotion() {
			if (!this.promotionText.trim()) {
				this.$Message.error('请输入促进内容');
				return;
			}

			boostService
				.promotionSubmit({
					cj_id: this.$route.params.id,
					cljl: this.promotionText.trim(),
					yyrq: this.nextTime,
				})
				.then(() => {
					this.$Message.success('数据录入成功');
				});
		},
		getTodoTask() {
			boostService.todoTask({ member_id: this.uid }).then((data) => {
				this.allTasks = data;
			});
		},
		goToNext() {
			if (this.allTasks.length > 0) {
				const { member_id, id } = this.allTasks[0];
				this.$router.push(`/promotion-manage/${member_id}/${id}`);
				window.location.reload();
			} else {
				this.$Message.warning('今日任务已完成');
			}
		},
		getProblems() {
			boostService.problemTypes().then((data) => {
				this.problemTypes = data.list;
				if (this.problemTypes.length > 0) {
					this.selectedProblem = this.problemTypes[0].id;
				}
			});
		},
		openModal(value) {
			this.modalTitle = value;
			this.newRemark = value === '备注' ? this.profileData.bz : '';
			this.showModal = true;
		},
		modalOK() {
			if (this.modalTitle === '主诉新增') {
				const params = {
					member_id: this.uid,
					describe: this.zs.describe,
					find_time: dayjs(this.zs.find_time).format('YYYY-MM-DD'),
				};
				boostService.screenZs(params).then(() => {
					this.$Message.success('主诉新增成功');
					this.getProfile();
					this.modalCancel();
				});
			} else if (this.modalTitle === '物质新增') {
				const params = {
					member_id: this.uid,
					type_id: this.fj.type_id,
					use_way: this.fj.use_way,
					start_time: dayjs(this.fj.start_time).format('YYYY-MM-DD'),
				};
				boostService.screenFj(params).then(() => {
					this.$Message.success('物质新增成功');
					this.getBehavior();
					this.modalCancel();
				});
			} else if (this.modalTitle === '训练新增') {
				const params = {
					member_id: this.uid,
					type_id: this.xl.type_id,
					use_way: this.xl.use_way,
					start_time: dayjs(this.xl.start_time).format('YYYY-MM-DD'),
				};
				boostService.screenXl(params).then(() => {
					this.$Message.success('训练新增成功');
					this.getBehavior();
					this.modalCancel();
				});
			} else if (this.modalTitle === '备注') {
				const params = { member_id: this.uid, bz: this.newRemark };
				boostService.screenRemark(params).then(() => {
					this.$Message.success('备注修改成功');
					this.getProfile();
					this.modalCancel();
				});
			}
		},
		modalCancel() {
			this.showModal = false;
			this.fj = {};
			this.zs = {};
			this.xl = {};
			this.newRemark = '';
		},
		getFjList() {
			boostService.screenFlList().then((data) => {
				this.fjList = data.fj;
				this.xlList = data.xl;
			});
		},
	},
	created() {
		this.setTimer();
		this.uid = this.$route.params.memberId;
		if (!this.uid) {
			this.$Message.error('未选择用户');
			return;
		}

		this.getProfile();
		this.getPromotion();
		this.getPlan();
		this.getAsk();
		this.getAlarm();
		this.getBehavior();
		this.getService();
		this.getNextTime();
		this.getTodoTask();
		this.getProblems();
		this.getFjList();
	},
	beforeDestroy() {
		clearTimeout(this.timer);
	},
};
</script>

<style lang="less">
.promotion-screen {
  background: url(@/assets/img/promotion/bg.png) center / 100% 100%;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;

  .blue {
    color: #5dbeb4;
  }

  .red {
    color: #d74543;
  }

  .yellow {
    color: #fdad02;
  }

  .font-20 {
    font-size: 20px;
    font-weight: 700;
  }

  .font-13 {
    font-size: 13px;
  }

  .time-panel {
    position: absolute;
    right: 40px;
    top: 20px;
    color: #fff;
    font-size: 20px;
  }

  .header {
    color: #fff;
    font-size: 38px;
    font-weight: 700;
    text-align: center;
    margin-top: 30px;
  }

  .title-promotion {
    padding: 12px 20px 6px;
  }

  .title-plan,
  .title-ask {
    padding: 12px 20px 2px;
  }

  .title-profile {
    padding: 5px 20px 0;
    display: flex;
    justify-content: space-between;
  }

  .profile-birthday {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .profile-img {
    height: 20px;
    width: 20px;
  }

  .profile-text {
    font-size: 16px;
    color: #fff;
  }

  .title-alarm {
    padding: 12px 0 8px;
  }

  .title-behavior {
    padding: 12px 0 6px;
  }

  .title-service {
    padding: 20px 0 15px;
  }

  .body {
    display: flex;
    gap: 30px;
    margin: 40px 80px;
  }

  .section {
    width: calc((100% - 60px) / 3);
  }

  .record-panel {
    background: url(@/assets/img/promotion/record.png) center / 100% 100% no-repeat;
  }

  .record-panel_promotion {
    height: 280px;
    margin-bottom: 30px;
  }

  .record-panel_plan {
    height: 270px;
    margin-bottom: 30px;
  }

  .record-panel_ask {
    height: 260px;
  }

  .profile-panel {
    background: url(@/assets/img/promotion/profile.png) center / 100% 100% no-repeat;
    height: 360px;
  }

  .input-panel {
    background: url(@/assets/img/promotion/narrow.png) center / 100% 100% no-repeat;
    height: 200px;
  }

  .profile-row {
    padding-left: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 2px;

    .name {
      color: #fff;
      font-size: 12px;
      width: 5em;
      text-align-last: justify;
    }

    .red {
      width: calc(100% - 4em - 20px);
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .profile-row-last {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 20px;

    .name {
      color: #fff;
      font-size: 12px;
    }
  }

  .input-row {
    display: flex;
    color: #a8d1ee;
    padding: 14px 20px;
  }

  .alarm-panel {
    background: url(@/assets/img/promotion/alarm.png) center / 100% 100% no-repeat;
    height: 220px;
    margin-bottom: 20px;
    padding: 0 30px 0 20px;
  }

  .behavior-panel {
    background: url(@/assets/img/promotion/wide.png) center / 100% 100% no-repeat;
    height: 330px;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .service-panel {
    background: url(@/assets/img/promotion/service.png) center / 100% 100% no-repeat;
    height: 300px;
    padding: 0 20px;
  }

  .table {
    color: #fff;
    width: 100%;
    table-layout: fixed;
    text-align: center;
  }

  .table-content {
    font-size: 12px;
    overflow: auto;
  }

  .table-promotion-content {
    height: 190px;
  }

  .table-plan-content {
    height: 190px;
  }

  .table-ask-content {
    height: 180px;
  }

  .table-alarm-content {
    height: 110px;
  }

  .table-behavior-content {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #1c7eb5;
    display: flex;

    td {
      padding: 2px 0;
    }

    .table {
      height: 46px;
    }

    .table-body {
      height: 115px;
      overflow: auto;
      font-size: 12px;

      .table {
        text-align: left;
      }
    }
  }

  .table-service-content {
    height: 175px;
  }

  .table-promotion,
  .table-ask {
    td {
      padding: 3px 0;
    }
  }

  .table-plan {
    td {
      padding: 5px 0;
    }
  }

  .table-profile {
    th {
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      padding: 6px 0;
    }

    td {
      color: #fdad02;
      padding: 8px 0;
      font-weight: 700;
    }
  }

  .table-alarm {
    td {
      height: 35px;
    }
  }

  .table-behavior {
    text-align: left;

    td {
      padding: 2px 0;
    }
  }

  .table-service {
    td {
      height: 30px;
    }
  }

  .button-panel {
    display: flex;
    gap: 10px;
    margin-left: auto;
  }

  .button {
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    border: #fff solid 1px;
    border-radius: 4px;
    padding: 2px 4px;
  }

  .textarea {
    background-color: transparent;
    border: none;
    color: #fff;
    width: 100%;
    resize: none;
    padding: 0 35px;
  }

  .select {
    background-color: transparent;
    border: #fff solid 1px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
  }
}</style>
